























import { mapGetters, mapState } from 'vuex'

export default {

	components: {
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue')
	},

	props: {
		fromLink: {
			type: Boolean,
			default: false,
		},
		defaultName: {
			type: String,
			default: '',
		},
		defaultCode: {
			type: String,
			default: '',
		}
	},

	data() {
		return {
			loading: false,
			name: '',
			code: ''
		}
	},

	computed: {
		...mapGetters('wAuth', [ 'isLoggedIn', 'user' ]),
	},

	mounted() {
		if (this.fromLink) {
			this.code = this.defaultCode
			this.name = this.isLoggedIn ? `${this.user.name} ${this.user.surname}` : this.defaultName
			if (this.code.length && this.name.length) {
				this.enter()
			}
		}
	},

	methods: {
		async enter() {
			const name = this.isLoggedIn ? `${this.user.name} ${this.user.surname}` : this.name
			if (!this.code || !name)
				return
			try {
				this.loading = true
				const response = await this.$store.dispatch('user/join', { code: this.code, name: name })
				const nextPage = response.exam.is_scored || response.exam.contains_multiselect ? 'TestInfo' : 'Test'
				await this.$router.push({ name: nextPage })
			} catch (err) {
				await this.$wToast.error(err)
			} finally {
				this.loading = false
			}
		}
	}
}
